import { number, oneOfType, shape, string } from 'prop-types'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { useTenant } from '../../../contexts/TenantContext'

import useGetListPolicies from './api/queries/useGetListPolicies'
import useFilteredData from './hooks/useFilteredData'
import useAllItems from './hooks/useAllItems'
import useGroupedOptions from './hooks/useGroupedOptions'

import ProductGroup from './ProductGroup/ProductGroup'
import PolicyPickerSkeleton from '../../LoadingSkeletons/PolicyPickerSkeleton'

import SearchBar from './components/SearchBar'
import FilterDropdown from './components/FilterDropdown'
import ToolbarButtons from './components/ToolbarButtons'

import { useExpansion } from './context/ExpansionContext'

import { getIconByName } from './utils/utils'
import Alert from '../../Alert/Alert'

const PolicyPicker = ({ selectedBackup }) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])

  // Tenant Provider
  const tenant = useTenant()

  const {
    data: groupedPolicies = [],
    isFetching,
    dataUpdatedAt,
    isError,
    error: errors,
    isLoading,
  } = useGetListPolicies(
    tenant[0].clientTenantId,
    selectedBackup,
    tenant[0].isSharedBaseline
  )

  const { toggleExpandAll, resetExpansionState } = useExpansion()

  const filteredData = useFilteredData(
    groupedPolicies,
    isLoading,
    searchTerm,
    selectedOptions
  )

  const handleSearchTermChange = term => {
    // Set the raw term for input without trimming (allow spaces while typing)
    setSearchTerm(term)

    // Trim and lowercase the term for matching purposes only
    const trimmedTerm = term.trim().toLowerCase()

    if (trimmedTerm !== '') {
      const matchingGroups = []

      groupedPolicies.forEach(group => {
        const groupMatches = group.name.toLowerCase().includes(trimmedTerm)

        const matchingSubGroups = group.subGroups.reduce((subAcc, subGroup) => {
          const subGroupMatches = subGroup.name
            .toLowerCase()
            .includes(trimmedTerm)

          const matchingCategories = subGroup.categories.reduce(
            (catAcc, category) => {
              const categoryMatches = category?.name
                ?.toLowerCase()
                .includes(trimmedTerm)

              const matchingItems = category.items.reduce((itemAcc, item) => {
                const itemMatches = item.displayName
                  .toLowerCase()
                  .includes(trimmedTerm)

                if (itemMatches) {
                  itemAcc.push(item.displayName)
                }

                return itemAcc
              }, [])

              if (categoryMatches || matchingItems.length > 0) {
                catAcc.push({
                  categoryName: category.name,
                  items: matchingItems,
                })
              }

              return catAcc
            },
            []
          )

          if (subGroupMatches || matchingCategories.length > 0) {
            subAcc.push({
              subGroupName: subGroup.name,
              categories: matchingCategories,
            })
          }

          return subAcc
        }, [])

        if (groupMatches || matchingSubGroups.length > 0) {
          matchingGroups.push({
            groupName: group.name,
            subGroups: matchingSubGroups,
          })
        }
      })

      if (matchingGroups.length > 0) {
        const itemsToExpand = matchingGroups.reduce((acc, group) => {
          acc.push(group.groupName)

          group.subGroups.forEach(subGroup => {
            acc.push(`${group.groupName}-${subGroup.subGroupName}`)

            subGroup.categories.forEach(category => {
              acc.push(
                `${group.groupName}-${subGroup.subGroupName}-${category.categoryName}`
              )

              category.items.forEach(item => {
                acc.push(
                  `${group.groupName}-${subGroup.subGroupName}-${category.categoryName}-${item}`
                )
              })
            })
          })

          return acc
        }, [])

        toggleExpandAll({
          parentGroup: null, // Use `null` or leave empty if not grouping at the top level
          items: itemsToExpand,
        })
      }
    } else {
      resetExpansionState()
    }
  }

  const allPolicies = useAllItems(groupedPolicies, isLoading)
  const groupedOptions = useGroupedOptions(groupedPolicies, isLoading)

  const customOrder = [
    'Entra',
    'Intune',
    'Defender',
    'SharePoint',
    'M365 Admin Center',
    'Other',
  ]

  // Sort based on the custom order
  const orderedProducts = filteredData.sort(
    (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name)
  )

  if (isLoading)
    return (
      <div className='p-5 bg-white min-w-[800px]'>
        <div className='mt-5'>
          <PolicyPickerSkeleton loadingTitle='Loading policies from Microsoft 365...' />
        </div>
      </div>
    )

  if (isError)
    return (
      <div className='p-5 bg-white min-w-[800px]'>
        <Alert type='error' title={errors.response.data.message} margin='mb-4'>
          {errors.response.data.errors?.map(error => (
            <ul>
              <li>{error}</li>
            </ul>
          ))}
        </Alert>
      </div>
    )

  return (
    <div className='p-5 bg-white min-w-[800px]'>
      <span className='xs-text'>
        {isFetching ? (
          <>
            Fetching latest data
            <FontAwesomeIcon icon={faSpinner} className='ml-2 animate-spin' />
          </>
        ) : (
          `Last fetched: ${moment(dataUpdatedAt).fromNow()}`
        )}
      </span>

      <div className='mt-5'>
        <div className='flex flex-row mb-4 space-x-4'>
          <div className='basis-1/2'>
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={handleSearchTermChange}
              suggestions={allPolicies}
            />
          </div>
          <div className='basis-1/3'>
            <FilterDropdown
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
              options={groupedOptions}
            />
          </div>
          <div className='basis-1/3'>
            <ToolbarButtons
              groupedPolicies={groupedPolicies}
              allPolicies={allPolicies}
            />
          </div>
        </div>
        {orderedProducts.map(group => (
          <ProductGroup
            key={group.name}
            title={group.name}
            subGroups={group.subGroups}
            icon={getIconByName(group.name)}
          />
        ))}

        {filteredData.length === 0 && (
          <div className='mt-5 text-center text-gray-500'>
            No policies found
          </div>
        )}
      </div>
    </div>
  )
}

const manualBackupShape = shape({
  Comment: string.isRequired,
  backupRunId: number.isRequired,
})

const automaticBackupShape = shape({
  backupRunId: number.isRequired,
  backupRunTime: string.isRequired,
})

PolicyPicker.defaultProps = {
  selectedBackup: null,
}

PolicyPicker.propTypes = {
  selectedBackup: oneOfType([manualBackupShape, automaticBackupShape]),
}

export default PolicyPicker
