import { object, arrayOf } from 'prop-types'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'

/**
 * ToolbarButtons component
 * @param {Array<{name: string, subGroups: Array<{name: string, categories: Array<{name: string, items: Array<{name: string, type: string}>}>}>}>} groupedPolicies - groupedPolicies
 * @returns {JSX.Element}
 */
const ToolbarButtons = ({ groupedPolicies, allPolicies }) => {
  const { state: selectionState, selectAll } = useSelection()
  const { isAllExpanded, toggleExpandAll } = useExpansion()

  // Reduce function to extract all names
  const allGroups = groupedPolicies.reduce((acc, group) => {
    // Add the group name as a top-level item
    acc.push(group.name)

    // Iterate over subGroups and add their names, prefixed with the group name
    group.subGroups.forEach(subGroup => {
      acc.push(`${group.name}-${subGroup.name}`) // Combine group name and subGroup name for uniqueness

      // Iterate over categories within the subGroups and add their names
      subGroup.categories.forEach(category => {
        acc.push(`${group.name}-${subGroup.name}-${category.name}`) // Combine all levels for uniqueness
      })
    })

    return acc
  }, [])

  const handleExpandAll = () => {
    toggleExpandAll({ parentGroup: '', items: allGroups })
  }

  const handleSelectAll = () => {
    selectAll(allPolicies, !selectionState.allSelected)
  }

  return (
    <div className='flex pt-[0.5px] justify-end'>
      <button
        type='button'
        onClick={handleExpandAll}
        className='p-2 bg-gray-800 text-white text-sm rounded mr-2'
      >
        {isAllExpanded('', allGroups) ? 'Collapse All' : 'Expand All'}
      </button>
      <button
        type='button'
        onClick={handleSelectAll}
        className='p-2 bg-gray-800 text-white text-sm rounded'
      >
        {selectionState.allSelected ? 'Deselect All' : 'Select All'}
      </button>
    </div>
  )
}

ToolbarButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  groupedPolicies: arrayOf(object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allPolicies: arrayOf(object).isRequired,
}

export default ToolbarButtons
