import { arrayOf, string } from 'prop-types'
import PrimaryGroup from '../PrimaryGroup/PrimaryGroup'
import ExpandButton from '../components/ExpandButton'
import Accordion from '../components/Accordion'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'
import { primaryGroupShape } from '../utils/propTypes'

/**
 * PolicyGroup - Primary Group under Product
 * @param {string} title - product name
 * @param {Array<{name: string, categories: Array<{name: string, items: Array<{name: string, type: string}>}>}>} subGroups - subGroups
 * @param {string} icon - product icon
 */
const ProductGroup = ({ title, subGroups, icon }) => {
  const { isExpanded, toggleExpand } = useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
    getGroupSelectedItemsCount,
  } = useSelection()

  const policiesInGroup = subGroups.flatMap(subGroup =>
    subGroup.categories.flatMap(category =>
      category.items.filter(policy => !policy.readOnly)
    )
  )

  const selectAllInGroup = policiesInGroup?.length
    ? policiesInGroup.every(policy =>
        selectedPolicies.some(selectedPolicy => selectedPolicy.id === policy.id)
      )
    : false

  const selectedPoliciesInGroup = getGroupSelectedItemsCount(policiesInGroup)

  const handleAllGroupSelect = () => {
    selectGroup(policiesInGroup, !selectAllInGroup)
  }

  const handleToggleExpand = () => {
    toggleExpand({ parentGroup: title })
  }

  const isGroupExpanded = isExpanded(title)

  return (
    <div
      className={`cursor-pointer mb-5 rounded-sm bg-neutral-50 ${isGroupExpanded ? 'border' : ''} drop-shadow-sm`}
    >
      <Accordion
        handleSelectAll={handleAllGroupSelect}
        toggleExpand={handleToggleExpand}
        selectAll={selectAllInGroup}
        tooltipText={`Select all in ${title}`}
        disabled={policiesInGroup?.length === 0}
      >
        <img src={icon} alt={title} className='w-8 h-8 mr-2' />
        <span className='font-bold text-lg'>
          {title} ({policiesInGroup.length})
          {selectedPoliciesInGroup > 0 &&
            ` - ${selectedPoliciesInGroup} ${selectedPoliciesInGroup === 1 ? 'policy' : 'policies'} selected`}
        </span>
        <div className='ml-auto flex items-center'>
          <ExpandButton isExpanded={isGroupExpanded} />
        </div>
      </Accordion>

      {isGroupExpanded && (
        <div className='p-4'>
          {subGroups.map(subGroup => (
            <PrimaryGroup
              key={subGroup.name}
              parentGroup={title}
              subGroup={subGroup}
            />
          ))}
        </div>
      )}
    </div>
  )
}

ProductGroup.defaultProps = {
  icon: '',
}

ProductGroup.propTypes = {
  title: string.isRequired,
  subGroups: arrayOf(primaryGroupShape).isRequired,
  icon: string,
}

export default ProductGroup
