import { useMemo } from 'react'

const useFilteredData = (
  groupedPolicies,
  isLoading,
  searchTerm,
  selectedOptions
) =>
  useMemo(() => {
    if (isLoading || groupedPolicies?.length === 0) return []

    const selectedProducts = selectedOptions
      .filter(option => option.group === 'Product')
      .map(option => option.label)
    const selectedOS = selectedOptions
      .filter(option => option.group === 'Primary Group')
      .map(option => option.label)
    const selectedCategories = selectedOptions
      .filter(option => option.group === 'Category')
      .map(option => option.label)
    const selectedTypes = selectedOptions
      .filter(option => option.group === 'Policy Type')
      .map(option => option.label)

    return groupedPolicies
      .filter(
        group =>
          selectedProducts.length === 0 || selectedProducts.includes(group.name)
      )
      .map(group => ({
        ...group,
        subGroups: group.subGroups
          .filter(
            subGroup =>
              selectedOS.length === 0 || selectedOS.includes(subGroup.name)
          )
          .map(subGroup => ({
            ...subGroup,
            categories: subGroup.categories
              .filter(
                category =>
                  selectedCategories.length === 0 ||
                  selectedCategories.includes(category.name)
              )
              .map(category => ({
                ...category,
                items: category.items.filter(
                  item =>
                    item.displayName
                      ?.toLowerCase()
                      .includes(searchTerm.trim().toLowerCase()) &&
                    (selectedTypes.length === 0 ||
                      selectedTypes.includes(item.type))
                ),
              }))
              .filter(category => category.items.length > 0),
          }))
          .filter(subGroup => subGroup.categories.length > 0),
      }))
      .filter(group => group.subGroups.length > 0)
  }, [searchTerm, selectedOptions, groupedPolicies, isLoading])

export default useFilteredData
