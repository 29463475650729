import { func, string, shape, number } from 'prop-types'
import { hexToRgb } from '../../../utils/colours'
import Tag from '../../Tag/Tag'

const CustomTagsOption = props => {
  const { data, innerRef, innerProps } = props

  return (
    <div ref={innerRef} {...innerProps} className='py-1 px-2'>
      <Tag
        text={data.label}
        description={data.description}
        colourRGB={hexToRgb(data.colour)}
      />
    </div>
  )
}

export default CustomTagsOption

CustomTagsOption.propTypes = {
  data: shape({
    label: string.isRequired,
    colour: string.isRequired,
  }).isRequired,
  innerRef: func.isRequired,
  innerProps: shape({
    onClick: func.isRequired,
    onMouseMove: func.isRequired,
    onMouseOver: func.isRequired,
    tabIndex: number.isRequired,
  }).isRequired,
}
