import Select from 'react-select'

import { arrayOf, func, shape, string } from 'prop-types'

/**
 * FilterDropdown component
 * @param {array} selectedOptions - The currently selected options
 * @param {function} setSelectedOptions - The function to set the selected options
 * @param {array} options - The available options
 * @returns {JSX.Element} The FilterDropdown component
 */
const FilterDropdown = ({ selectedOptions, setSelectedOptions, options }) => {
  const handleChange = newSelectedOptions => {
    setSelectedOptions(newSelectedOptions)
  }

  return (
    <Select
      isMulti
      value={selectedOptions}
      onChange={handleChange}
      options={options}
      placeholder='Select Filters'
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#1f2937' : '#d1d5db',
          borderRadius: '0.125rem',
          fontSize: '0.875rem',
        }),
      }}
    />
  )
}

const optionShape = shape({
  group: string.isRequired,
  value: string.isRequired,
  label: string.isRequired,
})

FilterDropdown.propTypes = {
  selectedOptions: arrayOf(optionShape).isRequired,
  setSelectedOptions: func.isRequired,
  options: arrayOf(
    shape({
      label: string,
      options: arrayOf(optionShape),
    })
  ).isRequired,
}

export default FilterDropdown
